import { ButtonHTMLAttributes, useMemo } from "react";

type ButtonType = "danger" | "carefully" | "primary" | "secondary" | "cancel";

export default function Button({
  className = "",
  disabled,
  children,
  buttonType = "primary",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & { buttonType?: ButtonType }) {
  const style = useMemo(() => {
    switch (buttonType) {
      case "primary":
        return " bg-blue-100 text-blue-800 hover:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-blue-900 dark:text-blue-400";
      case "secondary":
        return " bg-teal-100 text-teal-800 hover:bg-teal-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-teal-900 dark:text-teal-500 dark:hover:text-teal-400";
      case "carefully":
        return " bg-yellow-100 text-yellow-800 hover:bg-yellow-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-yellow-900 dark:text-yellow-500 dark:hover:text-yellow-400";
      case "danger":
        return " bg-red-100 text-red-800 hover:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-900 dark:text-red-500 dark:hover:text-red-400";
      case "cancel":
        return " bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:hover:bg-white/20 dark:text-neutral-400 dark:hover:text-neutral-300";
    }
  }, [buttonType]);

  return (
    <button
      {...props}
      className={
        `${style} py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none ${
          disabled && "opacity-25"
        } ` + className
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
